import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "../../util/useIntl";
import { useStrapiNavigationMenu } from "../useStrapiNavigationMenu";
import { StrapiNavigationAction } from "../StrapiNavigationAction"
import { Row, Column } from "../../components"


export const SubMenu = ({ data, columns = 1 }) => {
  const menu = useStrapiNavigationMenu(data.id);

  const { intl } = useIntl();

  const href = intl(menu.link?.href).get();

  const cols = menu.children.reduce(
    (acc, curr, index) => {
      const col = columns !== 1 ? Math.floor(index / (menu.children.length / columns)) : 0;
      acc[col].push(curr)
      return acc
    },
    new Array(columns).fill([])
  );

  return (
    <>
      { href ? (
        <a className="font-bold text-gray-700 hover:underline py-m px-s" href={href}>{menu.label.text}</a>
      ) : (
        <h5 className="p-s font-bold text-gray-600">{menu.label.text}</h5>
      )}
      <Row>
        {cols.map((links, index) => (
          <Column key={index}>
            {links.map((link) => {
              return link.action.id && (
                <>
                  <StrapiNavigationAction
                    level={2}
                    id={link.action.id}
                    key={link.action.id}
                  />
                </>
              )
            })}
        </Column>
        ))}
      </Row>
    </>
  )
}

SubMenu.propTypes = {
  columns: PropTypes.number
}