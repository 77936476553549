import React from "react"

import { useStrapiNavigationMenu } from "../useStrapiNavigationMenu"
import { StrapiNavigationAction } from "../StrapiNavigationAction"
import { SubMenu } from "./SubMenu"
import { useIntl } from "../../util/useIntl"
import * as constants from "../strapiComponentConstants"

export const StrapiSideMenu = ({ id }) => {
  const { intl } = useIntl()
  const menu = useStrapiNavigationMenu(id)

  const children = menu.children.map(child => {
    switch (child.__typename) {
      case constants.NAVIGATION_ACTION_REFERENCE:
        return (
          child.action && (
            <StrapiNavigationAction
              id={child.action.id}
              key={`action-${child.id}`}
            />
          )
        )
      case constants.NAVIGATION_MENU_REFERENCE:
        return (
          <SubMenu data={child.menu} columns={1} key={`menu-${child.id}`} />
        )
      default:
        return null
    }
  })

  return (
    <>
      <h2 className="text-heading-m px-s my-m">{intl(menu.label).get()}</h2>
      {children}
    </>
  )
}
