import styled from "styled-components";
import { theme } from "twin.macro";

export const Sidebar = styled.div.attrs({
  className: "shadow-xl rounded-3xl w-full p-s pb-m bg-gray-100"
})`
  @media (min-width: ${theme("screens.lg")}) {
    position: sticky;
    top: calc(var(--navbar-height) + 16px);
  }
`;